import { useContext, useState, useMemo } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Column,
  Content,
  DataTable,
  DataTableSkeleton,
  DatePicker,
  DatePickerInput,
  Dropdown,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  Tile,
  OverflowMenu,
  OverflowMenuItem,
  Modal,
  Tabs,
  Tab,
} from "carbon-components-react";

import { Add16, DocumentExport20 } from "@carbon/icons-react";

import dayjs from "dayjs";
import useSWR, { useSWRConfig } from "swr";
import { DateTimeFormat } from "../../utils/datetime";
import { UserContext, CentersContext } from "../../context";
import { URL, Fetcher as F } from "../../api";
import * as A from "../../utils/array";
import {
  defaultPageSize,
  defaultPageSizeOptions,
} from "../../components/Viewer";

const headersForDaily = [
  {
    key: "center",
    name: "館別",
  },
  {
    key: "date",
    name: "送餐日",
  },
  {
    key: "progress_status",
    name: "報表狀態",
  },
  {
    key: "created",
    name: "產生時間",
  },

  {
    key: "report_file",
    name: "報表",
  },
];

const headersForCustom = [
  {
    key: "center",
    name: "館別",
  },
  {
    key: "date",
    name: "報表區間",
  },
  {
    key: "progress_status",
    name: "報表狀態",
  },
  {
    key: "created",
    name: "產生時間",
  },

  {
    key: "report_file",
    name: "報表",
  },
];

const createQueryString = ({ center, offset, limit, isCustom }) => {
  const params = new URLSearchParams();
  params.append("expand", "center");
  if (center !== undefined) {
    const cid = center.id;
    if (cid !== 0) {
      params.append("center", cid);
    }
  }
  if (offset !== undefined) {
    params.append("offset", offset);
  }
  if (limit !== undefined) {
    params.append("limit", limit);
  }
  let report_type = "daily";
  if (isCustom !== undefined) {
    if (isCustom === false) {
      report_type = "custom";
    }
  }
  params.append("report_type", report_type);
  return params.toString();
};

export const ExportFoodVendorHistory = () => {
  const { list: centerList } = useContext(CentersContext);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(defaultPageSize);
  const offset = (page - 1) * limit;

  const [center, setCenter] = useState();
  const [isCustomRange, setIsCustomRange] = useState(false);

  const queryString = useMemo(
    () => createQueryString({ center, offset, limit, isCustomRange }),
    [center, offset, limit, isCustomRange]
  );

  const [realyQuery, setRealyQuery] = useState(queryString);

  const {
    token: { access },
  } = useContext(UserContext);
  let url = `${URL.reportOfExportFoodVendor}?${realyQuery}`;
  const { data } = useSWR(access && [url], F.withToken);

  const handleTabChange = async (evt) => {
    const tabIndex = evt.selectedIndex;
    console.debug(`tab`, tabIndex);

    if (tabIndex === 0) {
      setIsCustomRange(true);
    } else {
      setIsCustomRange(false);
    }
    const queryString = createQueryString({
      center,
      offset,
      limit,
      isCustomRange,
    });
    setRealyQuery(queryString);
  };

  const isLoading = !data;

  return (
    <Content>
      <Breadcrumb>
        <BreadcrumbItem href="/foodvendor" isCurrentPage>
          膳食統計
        </BreadcrumbItem>
        <BreadcrumbItem href="" isCurrentPage>
          清單
        </BreadcrumbItem>
      </Breadcrumb>

      <Tile>膳食統計</Tile>

      <Grid>
        <Column md={2}>
          <Dropdown
            id="center-selector"
            titleText="館別"
            label="全部"
            items={A.insert(centerList, { id: 0, name: "全部" }, 0)}
            itemToString={(item) => item.name}
            selectedItem={center}
            onChange={(v) => {
              const centerId = v.selectedItem;
              setCenter(centerId);
              const queryString = createQueryString({
                centerId,
                offset,
                limit,
                isCustomRange,
              });
              setRealyQuery(queryString);
            }}
          />
        </Column>

        <Tabs type="container" onChange={handleTabChange}>
          <Tab label="每日膳食統計表">
            {isLoading ? (
              <DataTableSkeleton showHeader={false} />
            ) : (
              <ExportFoodVendorHistoryTable
                page={page}
                offset={offset}
                limit={limit}
                inputData={data}
                isCustom={false}
                onPageChange={({ page, pageSize }) => {
                  const limit = pageSize;
                  const offset = (page - 1) * limit;
                  // offset and limit will not be update until the next event loop iteration
                  setPage(page);
                  setLimit(limit);
                  // so we update the real query by ourselves
                  const queryString = createQueryString({
                    center,
                    offset,
                    limit,
                  });
                  setRealyQuery(queryString);
                }}
              />
            )}
          </Tab>
          <Tab label="客製化膳食統計表">
            {isLoading ? (
              <DataTableSkeleton showHeader={false} />
            ) : (
              <ExportFoodVendorHistoryTable
                page={page}
                offset={offset}
                limit={limit}
                inputData={data}
                isCustom={true}
                onPageChange={({ page, pageSize }) => {
                  const limit = pageSize;
                  const offset = (page - 1) * limit;
                  // offset and limit will not be update until the next event loop iteration
                  setPage(page);
                  setLimit(limit);
                  // so we update the real query by ourselves
                  const queryString = createQueryString({
                    center,
                    offset,
                    limit,
                    isCustomRange,
                  });
                  setRealyQuery(queryString);
                }}
              />
            )}
          </Tab>
        </Tabs>
      </Grid>
    </Content>
  );
};

function ExportFoodVendorHistoryTable({
  page,
  limit,
  inputData,
  onPageChange,
  isCustom,
}) {
  const { mutate } = useSWRConfig();

  const { list: centerList } = useContext(CentersContext);

  const pageSize = limit;
  const total = (inputData && inputData.count) || 0;
  const data = (inputData && inputData.results) || [];

  const cleanData = data.map(({ center, query_values, ...other }) => {
    const { date } = query_values;
    return {
      ...other,
      center: center?.name ?? "",
      date: date ?? "",
    };
  });

  const pageProps = {
    disabled: !data,
    page: page,
    totalItems: total,
    backwardText: "前一頁",
    forwardText: "下一頁",
    pageSize: pageSize,
    pageSizes: defaultPageSizeOptions,
    itemsPerPageText: "顯示資料數：",
    onChange: onPageChange,
  };

  const [openModal, setOpenModal] = useState(false);
  const [modalStatus, setModalStatus] = useState("inactive");

  const [center, setCenter] = useState();
  const [date, setDate] = useState();

  const handleSubmit = async () => {
    if (!center || !date) {
      console.debug(`no center or no date`, center, date);
      return;
    }

    setModalStatus("active");
    var dateString = null;
    if (date !== undefined) {
      dateString = dayjs(date).format(DateTimeFormat.date);
    }
    const payload = {
      report_type_str: "daily",
      query_values: {
        date: dateString,
        center: center.id,
      },
    };
    await F.post(URL.reportOfExportFoodVendor, payload);
    mutate([URL.reportOfExportFoodVendor]);
    setOpenModal(false);
    setModalStatus("finished");
  };

  return (
    <>
      <Modal
        open={openModal}
        modalHeading="新增報表"
        primaryButtonText="新增"
        secondaryButtonText="取消"
        hasScrollingContent
        isFullWidth
        loadingStatus={modalStatus}
        onRequestClose={() => setOpenModal(false)}
        onSecondarySubmit={() => setOpenModal(false)}
        onRequestSubmit={handleSubmit}
      >
        <Dropdown
          id="drop-center"
          titleText="館別"
          label="選擇館別"
          items={centerList}
          itemToString={(item) => item.name}
          selectedItem={center}
          onChange={(v) => {
            setCenter(v.selectedItem);
          }}
        />
        {isCustom ? (
          <DatePicker
            dateFormat="Y/m/d"
            datePickerType="range"
            onChange={([startDate, endDate]) => {
              /* setDate(date) */
            }}
          >
            <DatePickerInput placeholder="yyyy/mm/dd" labelText="起始日期" />
            <DatePickerInput placeholder="yyyy/mm/dd" labelText="結束日期" />
          </DatePicker>
        ) : (
          <DatePicker
            dateFormat="Y/m/d"
            datePickerType="single"
            onChange={(date) => setDate(date)}
          >
            <DatePickerInput placeholder="yyyy/mm/dd" labelText="報表日期" />
          </DatePicker>
        )}
      </Modal>
      <DataTable
        rows={cleanData}
        headers={isCustom ? headersForCustom : headersForDaily}
      >
        {({
          rows,
          headers,
          getHeaderProps,
          getRowProps,
          getTableProps,
          getToolbarProps,
          getTableContainerProps,
        }) => (
          <TableContainer {...getTableContainerProps()}>
            <TableToolbar
              {...getToolbarProps()}
              aria-label="data table toolbar"
            >
              <TableToolbarContent>
                <Button renderIcon={Add16} onClick={() => setOpenModal(true)}>
                  新增報表
                </Button>
              </TableToolbarContent>
            </TableToolbar>

            <Table {...getTableProps()} isSortable>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader
                      key={header.key}
                      {...getHeaderProps({ header })}
                      isSortable={header.key !== "actions"}
                    >
                      {header.name}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id} {...getRowProps({ row })}>
                    {row.cells.map((cell) => {
                      const { header = "" } = cell && cell.info;
                      /* const id = cell.id.split(":")[0]; */
                      /* console.debug(`id`, id, cell.value); */
                      let content;
                      switch (header) {
                        case "created":
                          if (cell.value !== null) {
                            content = dayjs(cell.value).format(
                              DateTimeFormat.datetime
                            );
                          } else {
                            content = cell.value;
                          }
                          break;
                        case "date":
                          if (cell.value !== null) {
                            content = dayjs(cell.value).format(
                              DateTimeFormat.date
                            );
                          } else {
                            content = cell.value;
                          }
                          break;
                        case "center":
                        case "progress_status":
                          content = cell.value;
                          break;
                        case "report_file":
                          content = (
                            <Button
                              kind="ghost"
                              renderIcon={DocumentExport20}
                              as="a"
                              href={cell.value}
                              disabled={
                                cell.value === null || cell.value === ""
                              }
                            />
                          );
                          break;
                        case "actions":
                          content = (
                            <OverflowMenu size="sm" flipped disabled>
                              <OverflowMenuItem itemText="下載檔案" href="" />
                            </OverflowMenu>
                          );
                          break;
                        default:
                          content = <span>?</span>;
                      }
                      return <TableCell key={cell.id}>{content}</TableCell>;
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <Pagination {...pageProps} />
          </TableContainer>
        )}
      </DataTable>
    </>
  );
}
